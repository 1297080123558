import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { DirectoryAuthService } from "@org-common/lib/directory-shared/directory-auth.service";
import { lastValueFrom } from "rxjs";
import { IntegratedArchitectureFrameworkService } from "../integrated-architecture-framework.service";

export enum ConfigureRoleDialogTabTitle {
    RoleDetails = "Role Details",
    KeyAccountabilities = "Key Accountabilities",
    Attributes = "Attributes",
    Skills = "Skills",
    PrimaryRelationships = "Primary Relationships",
    People = "People",
    Systems = "Systems",
}

export interface IConfigureRoleDialogData {
    role: Role;
    changedEntities?: IBreezeEntity[];
    allowRoleConnectionTypeSelection?: boolean;
}

interface ITabItem {
    title: ConfigureRoleDialogTabTitle;
    template: string;
    key?: string;
    isEditorValid?: boolean,
}

@Component({
    selector: "adapt-configure-role-dialog",
    templateUrl: "./configure-role-dialog.component.html",
    styleUrls: ["./configure-role-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ConfigureRoleDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IConfigureRoleDialogData> implements OnInit {
    public readonly dialogName = "ConfigureRoleDialog";

    public readonly items: ITabItem[] = [];

    public isCreatingAndAddingSystem = false;

    private readonly guidance = this.architectureService.roleGuidance;

    public readonly connectionTypes = [ConnectionType.Employee, ConnectionType.Stakeholder];

    public roleLabelChanged = false;

    public isSystemAllocatedRole = false;
    public isRoleAccessReadOnly = false;

    public readonlyPeopleTabMessage = "";

    public isEditorValid = true;

    public changedLabelLocations: LabelLocation[] = [];

    private roleConnectionsChanged: RoleConnection[] = [];
    private changedSystemEntities: IBreezeEntity[] = [];

    public get entitiesToConfirm() {
        return this.getChangedEntities();
    }

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IConfigureRoleDialogData,
        protected commonDataService: CommonDataService,
        private architectureService: IntegratedArchitectureFrameworkService,
        private authorisationService: AuthorisationService,
        private navigationHierarchyService: NavigationHierarchyService,
    ) {
        super();
    }

    public async ngOnInit() {
        this.isSystemAllocatedRole = this.data.role.extensions.isSystemAllocatedRole();

        const canManageRoles = await this.authorisationService.promiseToGetHasAccess(DirectoryAuthService.ManageRoles);
        const canEditTier2 = await this.architectureService.promiseToVerifyTier2EditAccess();
        this.setupTabs(canManageRoles, canEditTier2);

        await this.fetchSupplementaryData();
    }

    public onRoleConnectionChanged(roleConnection: RoleConnection) {
        if (this.roleConnectionsChanged.indexOf(roleConnection) < 0) {
            this.roleConnectionsChanged.push(roleConnection);
        }
    }

    public get isInvalid() {
        return !this.data.role.label || this.isCreatingAndAddingSystem || this.items.some((i) => !i.isEditorValid);
    }

    private setupTabs(canManageRoles: boolean, canEditTier2: boolean) {
        this.items.push(
            {
                title: ConfigureRoleDialogTabTitle.RoleDetails,
                template: "detailTemplate",
                isEditorValid: true,
            },
            {
                title: ConfigureRoleDialogTabTitle.KeyAccountabilities,
                template: "propertyTemplate",
                key: "keyAccountabilities",
                isEditorValid: true,
            },
            {
                title: ConfigureRoleDialogTabTitle.Attributes,
                template: "propertyTemplate",
                key: "attributes",
                isEditorValid: true,
            },
            {
                title: ConfigureRoleDialogTabTitle.Skills,
                template: "propertyTemplate",
                key: "capabilities",
                isEditorValid: true,
            },
            {
                title: ConfigureRoleDialogTabTitle.PrimaryRelationships,
                template: "propertyTemplate",
                key: "relationships",
                isEditorValid: true,
            },
        );

        if (this.isSystemAllocatedRole || this.isRoleAccessReadOnly || !this.data.role.isActive() || (!canManageRoles && !canEditTier2)) {
            this.items.push({
                title: ConfigureRoleDialogTabTitle.People,
                template: "peopleTemplate",
                isEditorValid: true,
            });

            if (!this.data.role.isActive()) {
                this.readonlyPeopleTabMessage = this.data.role.label + " is already archived and can no longer be allocated.";
            } else if (this.isSystemAllocatedRole) {
                this.readonlyPeopleTabMessage = this.data.role.label + " is a system role that cannot be allocated here. Please allocate value stream leaders in the value stream, key function leaders in the key function, and cultural leaders in the cultural leadership framework.";
            } else if (this.isRoleAccessReadOnly) {
                // TODO: needs a better message here
                this.readonlyPeopleTabMessage = this.data.role.label + " is a system role that cannot be allocated here.";
            } else {
                this.readonlyPeopleTabMessage = "You do not have permission to allocate this role.";
            }
        } else {
            this.items.push({
                title: ConfigureRoleDialogTabTitle.People,
                template: "editPeopleTemplate",
                isEditorValid: true,
            });
        }

        if (canEditTier2 || canManageRoles) {
            // person get access to this
            this.items.push({
                title: ConfigureRoleDialogTabTitle.Systems,
                template: "systemsTemplate",
                isEditorValid: true,
            });
        }
    }

    private async fetchSupplementaryData() {
        const supplementaryData = await lastValueFrom(this.architectureService.getRoleSupplementaryDataById(this.data.role.roleId));

        const isCopyingRole = this.data.role.roleId < 0 && this.data.role.label;
        if (!supplementaryData && !isCopyingRole) {
            const supplementaryDataDefaults = {
                roleId: this.data.role.roleId,
            };

            return lastValueFrom(this.architectureService.createRoleSupplementaryData(supplementaryDataDefaults));
        }
    }

    public onRoleLabelChanged() {
        this.roleLabelChanged = true;
    }

    public getGuidance(key: string) {
        const guidance = this.guidance;
        const index = key as keyof typeof guidance;
        return guidance[index];
    }

    public getSupplementaryData(key: string) {
        const supplementaryData = this.data.role.supplementaryData;
        if (supplementaryData) {
            const index = key as keyof typeof supplementaryData;
            return this.data.role.supplementaryData![index] as string;
        }
    }

    public setSupplementaryData(key: string, value: string) {
        const supplementaryData = this.data.role.supplementaryData;
        if (supplementaryData) {
            const index = key as keyof typeof supplementaryData;
            (this.data.role.supplementaryData![index] as string) = value;
        }
    }

    /* Dialog Buttons */
    public async ok() {
        const changedEntities = this.getChangedEntities();
        await lastValueFrom(this.commonDataService.saveEntities(changedEntities));

        if (this.roleConnectionsChanged.length > 0) {
            this.architectureService.emitRoleConnectionChange();
        }

        if (this.roleLabelChanged) {
            this.navigationHierarchyService.updateActiveNodeFromUrlIfRouteParamMatchesValue("roleId", this.data.role.roleId);
        }

        super.resolve(this.data);
    }

    private getChangedEntities(): IBreezeEntity[] {
        let changedEntities: IBreezeEntity[] = [this.data.role, this.data.role.supplementaryData!, ...this.changedSystemEntities, ...this.changedLabelLocations];

        changedEntities = changedEntities.filter((e) => !!e);

        // can also be additional role permissions copied but unsaved, passed into this dialog for further editing before saving
        if (Array.isArray(this.data.changedEntities)) {
            changedEntities = changedEntities.concat(this.data.changedEntities);
        }

        // role connection changes (edit people tab)
        this.roleConnectionsChanged = this.roleConnectionsChanged.filter((roleConnection) => {
            if (!roleConnection.roleId || !roleConnection.connectionId) {
                // incomplete - discard
                if (!roleConnection.entityAspect.entityState.isDetached()) {
                    // check to ensure previously rejected entity is not rejected again
                    roleConnection.entityAspect.rejectChanges();
                }
                return false;
            } else {
                return true;
            }
        });
        changedEntities = changedEntities.concat(this.roleConnectionsChanged);

        changedEntities = ArrayUtilities.distinct(changedEntities);

        return changedEntities;
    }

    public onConnectionTypeChanged(connectionType: ConnectionType) {
        this.data.role.connectionType = connectionType;
    }

    public onSystemEntitiesChanged(entities: IBreezeEntity[]) {
        this.changedSystemEntities = entities;
    }

    public onCreatingAndAddingSystemChanged(currentStatus: boolean) {
        this.isCreatingAndAddingSystem = currentStatus;
    }

    public get getTitlePrefix() {
        const isAdding = this.data.role.roleId < 0 && !this.data.changedEntities;
        const isCopying = this.data.role.roleId < 0 && this.data.role.label;
        if (isAdding) {
            return "Add ";
        } else if (isCopying) {
            return "Copying ";
        } else {
            return "Configure ";
        }
    }
}
